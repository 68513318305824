<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    :class="{ hidden: this.showEditNotesModal == false }"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-lg w-full sm:align-middle sm:my-8"
      >
        <form @submit.prevent="submit" v-if="!isSuccessUpdateNotes">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div>
              <div class="mt-3 sm:mt-0 sm:ml-4 text-left">
                <div class="mt-2">
                  <div class="w-full mt-3">
                    <label
                      for="payment_method"
                      class="block text-sm font-medium text-gray-700"
                      >Payment Method</label
                    >
                    <select
                      v-model="form.payment_method"
                      id="payment_method"
                      name="payment_method"
                      class="relative w-full px-4 py-2 mt-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="transfer">Transfer</option>
                      <option value="cash">Cash</option>
                    </select>
                    <span
                      class="text-red-500 text-sm block mt-1"
                      v-if="errors.payment_method"
                    >
                      {{ errors.payment_method[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-4 text-left">
                <div class="mt-2">
                  <div class="w-full mt-3">
                    <label
                      for="price"
                      class="block text-sm font-medium text-gray-700"
                      >Note</label
                    >
                    <textarea
                      v-model="form.note"
                      type="text"
                      name="price"
                      id="price"
                      autocomplete="off"
                      class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    <span
                      class="text-red-500 text-sm block mt-1"
                      v-if="errors.note"
                    >
                      {{ errors.note[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <div v-if="loadingUpdateBulk" class="btn-spinner mt-1 mr-2" />
              Save
            </button>
            <button
              @click="cancelEditNotesModal()"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </form>

        <div v-if="isSuccessUpdateNotes">
          <div class="mt-3 sm:mt-0 text-center px-4 py-3">
            <p class="text-lg font-bold">Success :)</p>
            <p mt="3">
              The payment status, payment method, and note have been updated for
              the selected transactions.
            </p>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              @click="closeEditNoteModal()"
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EditNoteModal",
  props: ["selectedTransactionIds"],
  computed: {
    ...mapState("transactionsHistory", {
      showEditNotesModal: (state) => state.showEditNotesModal,
      form: (state) => state.form,
      errors: (state) => state.errors,
      loadingUpdateBulk: (state) => state.loadingUpdateBulk,
      isSuccessUpdateNotes: (state) => state.isSuccessUpdateNotes,
    }),
  },
  methods: {
    closeEditNoteModal() {
      this.$store.dispatch("transactionsHistory/openEditNotesModal", false);
      this.$store.dispatch("transactionsHistory/clearSelectedTransactionIds");
      this.$store.dispatch(
        "transactionsHistory/clearSuccessUpdateNotes",
        false
      );

      this.$store.dispatch("transactionsHistory/getTransactions", {
        page: this.$route.query.page,
        search: this.$route.query.search,
        payment_status: this.$route.query.payment_status,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        term_of_payment: this.$route.query.term_of_payment,
      });

      this.$store.dispatch("transactionsHistory/sumGrandTotal", {
        payment_status: this.$route.query.payment_status,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        term_of_payment: this.$route.query.term_of_payment,
      });
    },
    cancelEditNotesModal() {
      this.$store.dispatch("transactionsHistory/openEditNotesModal", false);
    },
    submit() {
      this.$store.dispatch("transactionsHistory/updatePaymentStatuses", {
        ids: this.selectedTransactionIds,
        paymentMethod: this.form.payment_method,
        note: this.form.note,
      });
    },
  },
};
</script>
