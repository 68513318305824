<template>
  <div class="mt-6 -mb-1 flex flex-wrap">
    <template v-for="(link, key) in links" :key="key">
      <div
        v-if="link.url === null"
        class="mr-1 mb-1 px-4 py-2 text-sm border rounded text-gray-400"
        :class="{ 'ml-auto hidden': link.label === 'Next' }"
        v-html="link.label"
      ></div>
      <router-link
        v-else
        :to="{
          name: activePage,
          query: {
            page: getPageNumber(link.url),
            search: searchKeyword,
            payment_status: paymentStatus,
            start_date: startDate,
            end_date: endDate,
            term_of_payment: termOfPayment,
          },
        }"
        class="mr-1 mb-1 px-4 py-2 text-sm border rounded hover:bg-white focus:border-indigo-500 focus:text-indigo-500"
        :class="{
          'bg-white': link.active,
          'ml-auto hidden': link.label === 'Next',
        }"
        v-html="link.label"
      ></router-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
    activePage: String,
    searchKeyword: String,
    paymentStatus: String,
    startDate: String,
    endDate: String,
    termOfPayment: String,
  },
  computed: {
    getPageNumber() {
      return (stringURL) => {
        const stringPageNumber = stringURL.split("?")[1]; // page=1
        const number = stringPageNumber.split("=")[1]; // 1
        return number;
      };
    },
  },
};
</script>
